import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import cx from "classnames";

import Picture from "components/picture";
import CustomLink from "components/link";
import CoreContainer from "components/core-container";
import SnapSlider from "components/snap-slider";
import "./image-card.css";

const ICON_LINK = "nextArrowIcon";

const ImageCard = ({ data = {} }) => {
  const { title, subTitle, cards, display } = data;
  const router = useRouter();
  const isBig = display === "big";

  return (
    <CoreContainer containerClassName="py-20" isFullWidth>
      <div className="mb-11 px-6 text-center">
        <h2 className="m-auto mx-auto mb-4 max-w-[780px] text-center text-4xl font-normal text-accent-900 lg:text-5xl">
          {title}
        </h2>
        {subTitle && <h3 className="mt-2 text-lg font-light">{subTitle}</h3>}
      </div>

      <SnapSlider itemClassName="mb-4">
        {cards?.map((card, index) => {
          const isIconLink = card?.link?.linkType === ICON_LINK;
          return (
            <div
              key={index}
              onClick={() => {
                if (card?.link?.url) {
                  router.push(card.link.url);
                }
              }}
              className={cx(
                "image-card-element group mx-2 select-none rounded-lg",
                isBig
                  ? "h-[358px] w-[305px] lg:h-[623px] lg:w-[623px]"
                  : "h-[214px] w-[214px] lg:h-[248px] lg:w-[363px]"
              )}
            >
              <div
                className={cx(
                  "relative flex h-full cursor-pointer items-start justify-start rounded-lg bg-neutral-200 text-accent-900",
                  card?.hasOverlay ? "!text-white" : null
                )}
              >
                {card?.hasOverlay && (
                  <div
                    className={cx(
                      "absolute top-0 left-0 z-[1] w-full rounded-t-lg",
                      isBig ? "overlay-big h-[265px]" : "overlay-standard h-[160px]"
                    )}
                  ></div>
                )}

                <div className={cx("z-[2]", isBig ? "pt-6 pl-5 lg:pt-7 lg:pl-8" : "p-6")}>
                  {card.title && (
                    <div className={cx("font-normal", isBig ? "text-3xl lg:text-4xl" : "text-xl lg:text-3xl")}>
                      {card.title}
                    </div>
                  )}
                  {card.subTitle && (
                    <div
                      className={cx(
                        "pr-2 font-normal",
                        isBig ? "mt-0.5 text-sm lg:mt-1 lg:text-md" : "text-xs font-medium"
                      )}
                    >
                      {card.subTitle}
                    </div>
                  )}
                </div>
                <Picture
                  picture={{ image: card.mainImage }}
                  layout="fill"
                  className="pointer-events-none z-0 rounded-lg"
                  objectFit="cover"
                  objectPosition="bottom center"
                />

                {card?.link && (
                  <div
                    className={cx(
                      "absolute",
                      isBig ? "bottom-[14px] left-[14px] lg:bottom-6 lg:left-8" : "bottom-5 left-5"
                    )}
                  >
                    <CustomLink
                      {...card.link}
                      title={card.link?.title || "Learn More"}
                      className={
                        isIconLink
                          ? " !p-2 group-hover:bg-black"
                          : "inline-flex items-center rounded-full border border-transparent !bg-[#ffffff] px-6 py-3 text-md font-semibold !text-accent-800 shadow-sm hover:opacity-80 focus:outline-none group-hover:border-neutral-700"
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </SnapSlider>
    </CoreContainer>
  );
};

ImageCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    display: PropTypes.oneOf(["big", "standard"]),
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        _key: PropTypes.string,
        _type: PropTypes.string,
        link: PropTypes.shape({
          url: PropTypes.string,
          page: PropTypes.shape({
            slug: PropTypes.string,
          }),
          title: PropTypes.string,
          isButton: PropTypes.bool,
          isInternal: PropTypes.bool,
          icon: PropTypes.any,
          newWindow: PropTypes.bool,
          shouldScroll: PropTypes.bool,
          styles: PropTypes.shape({
            style: PropTypes.string,
            isLarge: PropTypes.bool,
            isBlock: PropTypes.bool,
          }),
        }),
      })
    ),
  }),
};

export default ImageCard;
